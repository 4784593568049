// Home.js
import React, { useEffect, useState } from 'react';
import FlightCard from './FlightCard';
import FlightSearch from "./FlightSearch";
import { Flight, LocationOption } from "../types/types";
import { Dayjs } from "dayjs";

const Home: React.FC = () => {
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "https://backend-912935791231.europe-west3.run.app";
    const FLIGHTS_PER_PAGE = 50;
    const DEFAULT_RETURN_DAYS = 4;
    const DEFAULT_DEPARTURE_DAYS = 7;
    // TODO remove all params coming from FlightSearch
    const [selectedDeparturePoint, setSelectedDeparturePoint] = useState<LocationOption | null>(null);
    const [responseReceived, setResponseReceived] = useState<boolean>(false);
    const [tileData, setTileData] = useState<Flight[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [filterText, setFilterText] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [showPriceDropsOnly, setShowPriceDropsOnly] = useState(false);
    const [showInternationalOnly, setShowInternationalOnly] = useState(false);

    const filteredItems = Array.isArray(tileData)
        ? tileData.filter(flight =>
            flight.cityTo.toLowerCase().includes(filterText.toLowerCase()) ||
            flight.countryTo.name.toLowerCase().includes(filterText.toLowerCase())
        ) : [];

    const totalPages = Math.min(Math.ceil(filteredItems.length / FLIGHTS_PER_PAGE), 10);
    const paginatedItems = filteredItems.slice(
        (currentPage - 1) * FLIGHTS_PER_PAGE,
        currentPage * FLIGHTS_PER_PAGE
    );

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleViewDetailsButtonClick = (item: Flight) => {
        console.log('Flight Selected:', item);
        localStorage.setItem("selectedFlight", JSON.stringify(item));
        window.open(`/flight_details`, '_blank');
    };

    const handleGetSearchResults = (departure: LocationOption | null,
                                     destination: LocationOption | null, 
                                     selectedDepartureStartDate: Dayjs | null, 
                                    selectedDepartureEndDate: Dayjs | null,
                                     selectedReturnDate: Dayjs | null) => {
        setCurrentPage(1);
        setResponseReceived(false); // Reset response status to show loading
        setTileData([]); // Clear the tile data
        setError(null); // Clear any previous error
        setSelectedDeparturePoint(departure);
        if (!departure) return;
        if (!selectedDepartureStartDate) {
            console.error('Please select a departure date');
            return;
        }
        console.log('Fetching flight deals for:', departure.code, selectedDepartureStartDate.toLocaleString())
        const departureDateStart = selectedDepartureStartDate.format('YYYY-MM-DD');
        const departureDateEnd = selectedDepartureEndDate ? selectedDepartureEndDate.format('YYYY-MM-DD') : null;

        if (selectedReturnDate) {
            const returnDateString = selectedReturnDate.format('YYYY-MM-DD');
            fetchFlightDeals(departure.type, departureDateStart, departureDateEnd, returnDateString, departure.code, destination?.code, destination?.type, true);
        } else {
            fetchFlightDeals(departure.type, departureDateStart, departureDateEnd, null, departure.code, destination?.code, destination?.type, true);
        }

    };

    // const updateUser = (newValue: User | null) => {
    //     setUser(newValue);
    // };
    //
    // function fetchUserSubscriptions(userEmail: string) {
    //     console.log('Fetching user subscriptions for user', user)
    //     if (userEmail) {
    //         fetch(`${apiUrl}/api/subscription?user_email=${userEmail}`)
    //             .then((response) => {
    //                 if (!response.ok) {
    //                     throw new Error('Failed to fetch flight subscriptions');
    //                 }
    //                 return response.json();
    //             }).then(data => {
    //                 console.log('Flight subscriptions found:', data);
    //                 setFlightSubscriptions(data);
    //             }
    //         ).catch((error) => {
    //             console.error('Error fetching flight subscriptions:', error);
    //         });
    //     }
    // }

    function fetchFlightDeals(departureType: string, departureStartDate: string | null, departureEndDate: string | null, returnDate: string | null = null, departureCode: string | null = null, destinationCode: string | null = null, destinationType: string | null = null, exactMatch: boolean = false) {
        if (departureEndDate == null) {
            departureEndDate = departureStartDate;
        }
        let url;
        if (exactMatch) {
            url = `${apiUrl}/api/price-drops?departure_type=${departureType}&departure_code=${departureCode}&departure_start_date=${departureStartDate}&departure_end_date=${departureEndDate}${returnDate ? `&return_date=${returnDate}` : ''}&exact_match=true`;
        } else {
            url = `${apiUrl}/api/price-drops?departure_type=${departureType}&departure_code=${departureCode}&departure_start_date=${departureStartDate}&departure_end_date=${departureEndDate}${returnDate ? `&return_date=${returnDate}` : ''}&exact_match=false`;
        }

        if (destinationType != null && destinationCode != null) {
            url = url + `&destination_type=${destinationType}&destination_code=${destinationCode}`
        }

        fetch(url)
            .then((response) => {
                setResponseReceived(true);

                if (!response.ok) {
                    if (response.status === 404) {
                        throw new Error("No flights deals found for " + departureCode + " on: " + departureStartDate + " to " + departureEndDate);
                    }
                    throw new Error("An unexpected error occurred.");
                }
                return response.json();
            })
            .then(data => {
                setTileData(data)
                setError(null);
            }
            ).catch(error => {
                console.error('Error getting flight results', error);
                setTileData([])
                setError(error.message);
            })
    }

    useEffect(() => {
        fetch('https://get.geojs.io/v1/ip/geo.json')
            .then(response => response.json())
            .then(data => {
                console.log('User location found:', data.country, data.country_code)
                data.code = data.country_code;
                data.name = data.country;
                setSelectedDeparturePoint(data);
                const today = new Date();
                // const today = new Date();
                const defaultDepartureStartDate = new Date(today);
                defaultDepartureStartDate.setDate(today.getDate() + DEFAULT_DEPARTURE_DAYS);
                const departureDateStr = defaultDepartureStartDate.toISOString().split('T')[0];

                const defaultDepartureEndDate = new Date(defaultDepartureStartDate);
                defaultDepartureEndDate.setDate(defaultDepartureEndDate.getDate() + DEFAULT_DEPARTURE_DAYS);
                const departureEndDateStr = defaultDepartureEndDate.toISOString().split('T')[0];

                const defaultReturnDate = new Date(defaultDepartureStartDate);
                defaultReturnDate.setDate(defaultReturnDate.getDate() + DEFAULT_RETURN_DAYS);
                const returnDateString = defaultReturnDate.toISOString().split('T')[0];
                fetchFlightDeals("Country", departureDateStr, departureEndDateStr, returnDateString, data.country_code, null, null, true);
            })
            .catch(error => {
                console.error('Error fetching the location:', error);
            })

    }, [])

    const handleShowPriceDropsOnly = (e: React.ChangeEvent<HTMLInputElement>) => {
        const originalData = [...tileData];
        setShowPriceDropsOnly(e.target.checked);
        if (e.target.checked) {
            console.log("Showing Price Drops Only");
            // setTileData(tileData.filter(flight => flight.reason === 'PRICE_DROP'));
        } else {
            // TODO this is not working
            console.log("Showing All Flights");
            // setTileData(originalData);
        }
    };

    const handleShowInternationalOnly = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowInternationalOnly(e.target.checked);
        const originalData = [...tileData]; // Create a copy of the original data
        if (e.target.checked) {
            console.log("Showing International Flights Only");
            const filteredData = originalData.filter(flight => flight.countryFrom.code !== flight.countryTo.code);
            setTileData(filteredData);
        } else {
            console.log("Showing All Flights");
            // Reset to original data
            setTileData(originalData);
        }
    };


    return (
        <div>
            <div>
                <br /><br /><br />
                <FlightSearch
                    handleGetSearchResults={handleGetSearchResults}
                />
                <br />
            </div>
            <br /><br />

            {responseReceived ? (
                <div className="pagination" style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                    <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            id="priceDropFilter"
                            checked={showPriceDropsOnly}
                            onChange={handleShowPriceDropsOnly}
                            style={{ marginRight: '8px' }}
                        />
                        <label htmlFor="priceDropFilter">Only Price Drops</label>
                    </div>
                    <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            id="internationalFilter"
                            checked={showInternationalOnly}
                            onChange={handleShowInternationalOnly}
                            style={{ marginRight: '8px' }}
                        />
                        <label htmlFor="internationalFilter">International Flights Only</label>
                    </div>
                    <h3>Showing Deals for: {selectedDeparturePoint?.name}</h3>
                    <input
                        type="text"
                        placeholder="Search in results..."
                        value={filterText}
                        onChange={handleFilterChange}
                    />
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            className={index + 1 === currentPage ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            ) : <h3>Loading Flight Deals...</h3>}

            <div className="grid-container">
                {paginatedItems.map((item) => (
                    <FlightCard
                        key={item.id}
                        item={item}
                        onButtonClick={handleViewDetailsButtonClick}
                    />
                ))}
            </div>

            <br /><br /><br />
            {error && (
                <div>
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
}

export default Home;