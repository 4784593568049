// FlightCard.tsx
import React, { useState } from 'react';
import dayjs from 'dayjs';
import {Flight} from '../types/types';
import './FlightCard.css';

interface FlightCardProps {
    item: Flight;
    onButtonClick: (item: Flight) => void;
}

const FlightCard: React.FC<FlightCardProps> = ({item, onButtonClick}) => {
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "https://backend-912935791231.europe-west3.run.app";

    const calculatePriceDropPercentage = (current_price: number, previous_price: number): number => {
        return ((current_price - previous_price) / previous_price) * 100;
    };

    const displayPriceInEuros = (value: number): string => {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    return (
        <div className="grid-item">
            <div className="card-image-container">
                <img
                    className="item-image"
                    src={`/images/${item.cityTo}.webp`}
                    onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = `/images/${item.countryTo.name}.webp`;
                        target.onerror = () => (target.src = `/images/default_travel_pic.webp`);
                    }}
                    alt={item.cityTo}
                />
                {(item.reason === 'CHEAPER' || item.reason === 'PRICE_DROP') && (
                    <div className={`price-badge ${item.reason.toLowerCase()}`}>
                        {item.reason === 'CHEAPER' ? '🔥 Great Deal!' : '📉 Price Drop!'}
                    </div>
                )}
                {item.reason === 'NEW_FLIGHT' && (
                    <div className="price-badge new-flight">
                        🛫 New Flight! 🛬
                    </div>
                )}
            </div>

            <div className="card-content">
                <h3 className="destination">
                    <div className="departure">
                        {item.cityFrom}
                        <span className="country">{item.countryFrom.name}</span>
                    </div>
                    <div className="arrow" style={{ margin: '0 10px', color: '#666' }}>→</div>
                    <div className="arrival">
                        {item.cityTo}
                        <span className="country">{item.countryTo.name}</span>
                    </div>
                    <span className="airline-name" style={{ fontSize: '1.1rem', color: '#666', display: 'block', marginTop: '4px' }} >
                        {item.route?.[0]?.airlineName}
                    </span>
                    {/* <span className="flight-no">Flight {item.route?.[0]?.operating_flight_no}</span> */}
                </h3>

                {item.route &&  item.route.length > 1 && (

                <div className="dates">
                        {dayjs(item.route[0].local_departure).format('DD MMM HH:mm')} - {dayjs(item.route[1].local_departure).format('DD MMM HH:mm')}
                    </div>
                )}
                
                {item.route &&  item.route.length === 1 && (
                    <div className="dates">
                        {dayjs(item.local_departure).format('DD MMM HH:mm')}
                    </div>
                )}

                {!item.route && (
                    <div className="dates">
                        {dayjs(item.local_departure).format('DD MMM HH:mm')}
                    </div>
                )}

                <div className="price-section">
                    {item.reason === 'CHEAPER' && (
                        <div className="price-alert">
                            <div className="current-price">€{displayPriceInEuros(item.fare.adults)}</div>
                            <div className="price-comparison">
                                <span className="avg-price">Avg. <s>€{displayPriceInEuros(item.avg_price)}</s></span>
                                <span className="savings">Save {Math.abs(Math.round(calculatePriceDropPercentage(item.fare.adults, item.avg_price)))}%</span>
                            </div>
                        </div>
                    )}
                    
                    {item.reason === 'PRICE_DROP' && (
                        <div className="price-alert">
                            <div className="current-price">€{displayPriceInEuros(item.fare.adults)}</div>
                            <div className="price-comparison">
                                <span className="previous-price">Was <s>€{displayPriceInEuros(item.previous_price)}</s></span>
                                <span className="savings">↓ {Math.abs(Math.round(calculatePriceDropPercentage(item.fare.adults, item.previous_price)))}%</span>
                            </div>
                        </div>
                    )}
                    {!item.reason && (
                        <div className="price-alert">
                            <div className="current-price">€{displayPriceInEuros(item.fare.adults)}</div>
                            <div className="price-comparison">
                                {/* adding empty span to make the price comparison section look the same as the other price alerts */}
                                <span className="avg-price"></span>
                                <span className="savings"></span>
                            </div>
                        </div>
                    )}
                </div>

                <button className="detail-button" onClick={() => onButtonClick(item)}>
                    View Details
                </button>
            </div>
        </div>
    );
};

export default FlightCard;