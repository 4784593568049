import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import 'chart.js/auto';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import { onAuthStateChanged, signOut, User } from "firebase/auth";
import { auth } from './components/firebase';
import Dashboard from "./components/Dashboard";
import NotFound from './components/NotFound';
import FlightDetails from "./components/FlightDetails";
import ContactUs from './components/ContactUs';
import ContactPage from './components/ContactPage';
import About from './components/About';

function App() {
    const [user, setUser] = useState<User | null>(null);
    const [flightSubscriptions, setFlightSubscriptions] = useState<any[]>([]);
    // const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "https://backend-912935791231.europe-west3.run.app";
    const apiUrl = process.env.REACT_APP_BACKEND_URL;
    console.log("Connecting to backend at:", apiUrl);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                // fetchUserSubscriptions(user.email!);
            } else {
                signOut(auth).then(() => {
                    console.log("Signed out successfully")
                }).catch((error) => {
                    console.log("Error signing out", error.message)
                });
            }
            return () => unsubscribe();
        });
    }, []);

    function fetchUserSubscriptions(userEmail: string) {
        if (userEmail) {
            fetch(`${apiUrl}/api/subscription?user_email=${userEmail}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch flight subscriptions');
                    }
                    return response.json();
                })
                .then(data => {
                    setFlightSubscriptions(data);
                })
                .catch((error) => {
                    console.error('Error fetching flight subscriptions:', error);
                });
        }
    }

    const updateUser = (newValue: User | null) => {
        setUser(newValue);
    };

    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <h1>Latest Flight Deals</h1>
                </header>
                <nav className="navbar">
                    <div className="navbar-links">
                        <Link to="/" className="link">Home</Link>
                        <Link to="/about" className="link">About</Link>
                        {/* <Link to="/dashboard" className="link">Dashboard</Link> */}
                        <Link to="/contact" className="link">Contact</Link>
                        {user ? 
                            <Link to="/" className="link" onClick={() => updateUser(null)}>Logout</Link> :
                            <Link to="/login" className="link">Login</Link>
                        }
                    </div>
                </nav>

                <section>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/flight_details" element={<FlightDetails />} />
                        <Route path="/login" element={<Login initUser={updateUser} />} />
                        <Route path="/contact" element={<ContactPage />} />
                        {/* <Route path="/dashboard" element={
                            <Dashboard 
                                user={user} 
                                flightSubscriptions={flightSubscriptions} 
                            />
                        } /> */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </section>
                <footer className="footer">
                    <ContactUs isFooter={true} />
                </footer>
            </div>
        </Router>
    );
}

export default App;