import React, {use, useEffect, useState} from "react";
import {Line} from "react-chartjs-2";
import {
    Container,
    Grid2,
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Stack,
} from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {auth} from "./firebase";
import {useNavigate} from "react-router-dom";
import dayjs, {Dayjs} from "dayjs";
import {Flight, Price} from "../types/types";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FlightDetails: React.FC = () => {
        const [flightData, setFlightData] = useState<Flight | null>(null);
        const [isLoading, setIsLoading] = useState<boolean>(true);
        const [priceHistory, setPriceHistory] = useState<Price[]>([]);
        const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "http://localhost:5000";

        const fetchStoredFlightData = () => {
            console.log("fetchStoredFlightData");
            const storedItem = localStorage.getItem("selectedFlight");
            console.log("storedItem:", storedItem);
            if (storedItem) {
                setFlightData(JSON.parse(storedItem));
                setIsLoading(false);
                // fetchPriceHistory();
            } else {
                console.log("No priceChartData found in local storage");
            }
        };

        useEffect(() => {
            fetchStoredFlightData();
        }, []);

        useEffect(() => {
            fetchPriceHistory();
        }, [flightData]);

        const fetchPriceHistory = async () => {
            if (!flightData) {
                console.log("No flight data found for price history");
                return;
            }
            try {
                const formattedDate = dayjs(flightData?.local_departure).format('YYYY-MM-DD');
                const response = await fetch(`${apiUrl}/api/price_history?flight_id=${flightData?.id}&departure_date=${formattedDate}`);
                const data = await response.json();
                console.log('Price history fetched:', data);
                setPriceHistory(data);
                // setFlightData(prevData => ({
                //     ...prevData!,
                //     price_history: data
                // }));
            } catch (error) {
                console.error('Error fetching price history:', error);
            }
        };

        const priceChartData = flightData && priceHistory.length > 0
            ? {
                labels: priceHistory.map(item => dayjs(item.collectedAt).format('DD MMM')),
                datasets: [
                    {
                        label: 'Price (€)',
                        data: priceHistory.map(item => item.price),
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderWidth: 2,
                        fill: true,
                    },
                ],
            } : null;

        const priceChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: 'category' as const, // Ensure the type is correctly se,
                    title: {
                        display: true,
                        text: 'Date',
                    },
                },
                y: {
                    beginAtZero: true,
                },
            },
        };

        const handleRedirect = () => {
            console.log('Redirecting to Kiwi.com');
            console.log("Selected Flight Deep Link: ", flightData?.deep_link);
            const kiwiLink = flightData?.deep_link;
            if (kiwiLink) {
                window.open(kiwiLink, '_blank');
            }
        };

        const navigate = useNavigate();
        const handleCloseFlightDetails = () => {
            console.log('Closing Flight Details');
            navigate('/');
        };

        const handleSubscribeFlight = () => {
            console.log('Subscribing to flight:', flightData);
            let user = auth.currentUser;
            if (!user) {
                console.log("User not logged in, please login to subscribe to flight");
                alert("Please login to subscribe to flight");
                return;
            }
            const flightDataDao = {
                flight_id: flightData?.id,
                departure_date: flightData?.local_departure,
                fly_from: flightData?.flyFrom,
                fly_to: flightData?.flyTo,
                price: flightData?.current_price,
                user_id: user.uid,
                user_email: user.email,
                collection_timestamp: new Date().toISOString()
            };

            console.log("Flight Data to subscribe: ", flightDataDao);
            fetch(`${apiUrl}/api/subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(flightDataDao),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Subscription successful:', data);
                })
                .catch(error => {
                    console.error('Error subscribing to flight:', error);
                });
        };

        const formatDuration = (seconds: number) => {
            const hours = Math.floor(seconds / 3600);
            const mins = Math.floor((seconds % 3600) / 60);
            return `${hours}h ${mins}m`;
        };

        // Show a loading spinner while fetching priceChartData
        if (isLoading) {
            return <div>Loading...</div>;
        }

        return (
            <Container
                maxWidth="md"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "top",
                    height: "100vh",
                    padding: "20px",
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Flight Details
                </Typography>


                {/* Flight Details Cards */}
                <Grid2 container spacing={3} sx={{ width: '100%', justifyContent: 'center' }}>
                     
                    <Grid2 sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Card sx={{ height: '100%', width: '100%', boxShadow: 3, borderRadius: "10px" }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom color="primary">
                                    ✈️ Outbound Flight
                                </Typography>
                                <Typography><strong>🔢 Flight Number:</strong> {flightData?.route?.[0]?.airline}{flightData?.route?.[0]?.flight_no}</Typography>
                                <Typography><strong>✈️ Airline:</strong> {flightData?.airlines[0]}, {flightData?.route?.[0]?.airlineName}</Typography>
                                <Typography><strong>📍 From:</strong> {flightData?.route?.[0]?.flyFrom} - {flightData?.cityFrom}</Typography>
                                <Typography><strong>🎯 To:</strong> {flightData?.route?.[0]?.flyTo} - {flightData?.cityTo}</Typography>
                                <Typography><strong>🛫 Departure:</strong> {dayjs(flightData?.local_departure).format('DD MMM YYYY HH:mm')}</Typography>
                                <Typography><strong>🛬 Arrival:</strong> {dayjs(flightData?.local_arrival).format('DD MMM YYYY HH:mm')}</Typography>
                                {flightData?.duration && (
                                    <Typography><strong>⏱️ Flight Duration:</strong> {formatDuration(flightData.duration.departure)}</Typography>
                                )}
                                {flightData?.bags_price && (
                                    <Box mt={2}>
                                        <Typography variant="subtitle2" gutterBottom>🧳 Baggage Prices:</Typography>
                                        {Object.entries(flightData.bags_price).map(([key, value]) => (
                                            <Typography key={key} color="text.secondary">
                                                {key} bag: €{Number(value).toFixed(2)}
                                            </Typography>
                                        ))}
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    </Grid2>

                    {/* Return Flight Details */}
                    {flightData?.route && flightData.route.length > 1 && (
                        <Grid2 sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ height: '100%', width: '100%', boxShadow: 3, borderRadius: "10px" }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom color="primary">
                                        🔄 Return Flight
                                    </Typography>
                                    <Typography><strong>🔢 Flight Number:</strong> {flightData.route[1]?.airline}{flightData.route[1]?.flight_no}</Typography>
                                    <Typography><strong>✈️ Airline:</strong> {flightData.route[1]?.airline}, {flightData.route[1]?.airlineName}</Typography>
                                    <Typography><strong>📍 From:</strong> {flightData.route[1]?.flyFrom} - {flightData.route[1]?.cityFrom}</Typography>
                                    <Typography><strong>🎯 To:</strong> {flightData.route[1]?.flyTo} - {flightData.route[1]?.cityTo}</Typography>
                                    <Typography><strong>🛫 Departure:</strong> {dayjs(flightData.route[1]?.local_departure).format('DD MMM YYYY HH:mm')}</Typography>
                                    <Typography><strong>🛬 Arrival:</strong> {dayjs(flightData.route[1]?.local_arrival).format('DD MMM YYYY HH:mm')}</Typography>
                                    {flightData?.duration && (
                                        <Typography><strong>⏱️ Flight Duration:</strong> {formatDuration(flightData.duration.return)}</Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid2>
                    )}
                    {/* Layover Information */}
                    {flightData?.route && flightData.route.length > 2 && (
                        <Grid2 sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ mt: 2, boxShadow: 3, borderRadius: "10px" }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom color="primary">
                                        Layover Information
                                    </Typography>
                                    {flightData?.route?.slice(1, -1).map((segment, index) => (
                                        <Box key={index} mb={2}>
                                            <Typography><strong>Layover at:</strong> {segment.cityTo} ({segment.flyTo})</Typography>
                                            {/* <Typography><strong>Duration:</strong> {formatDuration(flightData.duration?.return)}</Typography> */}
                                        </Box>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid2>
                    )}
                </Grid2>

                {/* Chart Section */}
                {priceChartData && (
                    <Box
                        sx={{
                            width: "70%",
                            height: "400px",
                            marginTop: "40px",
                        }}
                    >
                        <Line data={priceChartData} options={priceChartOptions}/>
                    </Box>
                )}

                {/* Buttons Section */}
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        marginTop: "20px",
                        justifyContent: "center",
                    }}
                >
                    <Button onClick={handleCloseFlightDetails} variant="outlined" color="secondary">
                        Back to Search
                    </Button>
                    <Button onClick={handleSubscribeFlight} variant="contained" color="primary">
                        Monitor Flight
                    </Button>
                    <Button onClick={handleRedirect} variant="contained" color="success">
                        Buy It
                    </Button>
                </Stack>
            </Container>
        );
    }
;

export default FlightDetails;