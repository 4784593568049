import React, { useState } from 'react';
import './ContactUs.css';

const ContactUs: React.FC<{ isFooter?: boolean }> = ({ isFooter = false }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [submitStatus, setSubmitStatus] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Replace with your actual API endpoint
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      setSubmitStatus('error');
    }
  };

  return (
    <div className={`contact-us ${isFooter ? 'footer-contact' : 'page-contact'}`}>
      <div className="contact-content">
        {!isFooter && <h1>Contact Us</h1>}
        <div className="contact-info">
          <div className="contact-details">
            <h3>{isFooter ? 'Contact Us' : 'Get in Touch'}</h3>
            <p>
              <i className="fas fa-envelope"></i>
              <a href="mailto:admin@glimpse.com">admin@glimpse.com</a>
            </p>
            {/* <p>
              <i className="fas fa-phone"></i>
              <a href="tel:+1234567890">+1 (234) 567-890</a>
            </p> */}
            <div className="social-links" style={{ marginTop: '15px' }}>
              <a href="https://twitter.com/flight_glimpse" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px' }}>
                <i className="fab fa-twitter"></i> Twitter
              </a>
              <a href="https://linkedin.com/company/flightglimpse" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin"></i> LinkedIn
              </a>
            </div>
          </div>

          {!isFooter && (
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-btn">
                Send Message
              </button>
              {submitStatus === 'success' && (
                <p className="success-message">Message sent successfully!</p>
              )}
              {submitStatus === 'error' && (
                <p className="error-message">Failed to send message. Please try again.</p>
              )}
            </form>
          )}
        </div>
        {isFooter && (
          <div className="footer-seo-text" style={{ 
            marginTop: '20px', 
            paddingTop: '20px', 
            borderTop: '1px solid #eee',
            fontSize: '0.9em',
            color: '#666',
            textAlign: 'center'
          }}>
            <p>
              FlightGlimpse - Your trusted flight price tracker for airline tickets, flight deals, and travel savings. 
              Track flight prices, get price alerts, and find the best flight deals across multiple airlines. 
              Whether you're planning a business trip or vacation, we help you find affordable flights and save on airfare.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUs; 