import React, {useState} from "react";
import {Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, TextField, InputAdornment} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Dayjs} from 'dayjs';
import axios from "axios";
import {LocationOption} from "../types/types";
import CalendarIcon from '@mui/icons-material/CalendarToday';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

interface CountryCitySelectorProps {
    handleGetSearchResults: (departure: LocationOption | null, destination: LocationOption | null, selectedDepartureStartDate: Dayjs | null, selectedDepartureEndDate: Dayjs | null, selectedReturnDate: Dayjs | null ) => void;
}

const FlightSearch: React.FC<CountryCitySelectorProps> = ({
                                                              handleGetSearchResults
                                                          }) => {
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL || "https://backend-912935791231.europe-west3.run.app";

    const [departureOptions, setDepartureOptions] = useState<LocationOption[]>([]);
    const [departureInputValue, setDepartureInputValue] = useState<string>("");
    const [selectedDepartureLocation, setSelectedDepartureLocation] = useState<LocationOption | null>(null); // For selected value
    const [selectedDepartureStartDate, setSelectedDepartureStartDate] = useState<Dayjs | null>(null);
    const [selectedDepartureEndDate, setSelectedDepartureEndDate] = useState<Dayjs | null>(null);
    const [departureLoading, setDepartureLoading] = useState(false);
    const [oneWay, setOneWay] = useState(false);

    const [destinationOptions, setDestinationOptions] = useState<LocationOption[]>([]);
    const [destinationInputValue, setDestinationInputValue] = useState<string>("");
    const [selectedDestinationLocation, setSelectedDestinationLocation] = useState<LocationOption | null>(null); // For selected value
    const [selectedReturnDate, setSelectedReturnDate] = useState<Dayjs | null>(null); // For selected value
    const [destinationLoading, setDestinationLoading] = useState(false);

    const fetchDepartureOptions = async (query: string) => {
        if (query.length < 2) return; // Optional: minimum query length

        setDepartureLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/locations/search`, {
                params: {location_query: query}
            });
            const locations = response.data.locations || [];
            setDepartureOptions(locations);
        } catch (error) {
            console.error("Error fetching options:", error);
            setDepartureOptions([]);
        } finally {
            setDepartureLoading(false);
        }
    };

    const fetchDestinationOptions = async (query: string) => {
        if (query.length < 2) return; // Optional: minimum query length

        setDestinationLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/locations/search`, {
                params: {location_query: query}
            });
            const locations = response.data.locations || [];
            setDestinationOptions(locations);
        } catch (error) {
            console.error("Error fetching options:", error);
            setDestinationOptions([]);
        } finally {
            setDestinationLoading(false);
        }
    };

    const handleSearchButtonClick = () => {
        handleGetSearchResults(selectedDepartureLocation, selectedDestinationLocation, selectedDepartureStartDate, selectedDepartureEndDate, selectedReturnDate);
    }

    return (
        <div style={{
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: 'white',
            padding: '10px 0',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                gap: 1,
                alignItems: 'center',
                width: '95%',
                padding: {xs: '0 10px', md: '0 20px'}
            }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={oneWay}
                            onChange={(e) => {
                                setOneWay(e.target.checked);
                                if (e.target.checked) {
                                    setSelectedReturnDate(null);
                                }
                            }}
                            size="small"
                        />
                    }
                    label="One Way"
                    sx={{ 
                        marginBottom: 1,
                        '& .MuiFormControlLabel-label': {
                            fontSize: '0.875rem'
                        }
                    }}
                />

                <Autocomplete
                    sx={{width: '100%'}}
                    options={departureOptions}
                    getOptionLabel={(option: LocationOption) => `${option.text}`}
                    value={selectedDepartureLocation}
                    onChange={(event, newValue: LocationOption | null) => {
                        setSelectedDepartureLocation(newValue);
                    }}
                    inputValue={departureInputValue}
                    onInputChange={(event, newValue) => {
                        setDepartureInputValue(newValue);
                        fetchDepartureOptions(newValue);
                    }}
                    loading={departureLoading}
                    filterOptions={(x) => x} // Important: disable client-side filtering
                    isOptionEqualToValue={(option, value) => option.text === value.text}
                    noOptionsText="Start typing to search for locations."
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Departure"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {departureLoading && <CircularProgress color="inherit" size={20}/>}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />

                <Autocomplete
                    sx={{width: '100%'}}
                    options={destinationOptions}
                    getOptionLabel={(option: LocationOption) => `${option.text}`}
                    inputValue={destinationInputValue}
                    value={selectedDestinationLocation}
                    onChange={(event, newValue: LocationOption | null) => {
                        setSelectedDestinationLocation(newValue);
                    }}
                    onInputChange={(event, newValue) => {
                        setDestinationInputValue(newValue);
                        fetchDestinationOptions(newValue);
                    }}
                    loading={destinationLoading}
                    filterOptions={(x) => x} // Important: disable client-side filtering
                    noOptionsText="Start typing to search for locations."
                    isOptionEqualToValue={(option, value) => option.text === value.text}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Destination"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {destinationLoading && <CircularProgress color="inherit" size={20}/>}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                        <DatePicker
                            label="Start Date"
                            value={selectedDepartureStartDate}
                            onChange={(newDate: Dayjs | null) => {
                                setSelectedDepartureStartDate(newDate);
                                // If end date is before new start date, clear it
                                if (selectedDepartureEndDate && newDate && selectedDepartureEndDate.isBefore(newDate)) {
                                    setSelectedDepartureEndDate(null);
                                }
                            }}
                            sx={{ flex: 1 }}
                            slots={{
                                openPickerIcon: CalendarTodayIcon,
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    helperText: "",
                                    placeholder: "Select start date",
                                },
                            }}
                            disablePast
                        />
                        <DatePicker
                            label="End Date"
                            value={selectedDepartureEndDate}
                            onChange={(newDate: Dayjs | null) => {
                                setSelectedDepartureEndDate(newDate);
                            }}
                            sx={{ flex: 1 }}
                            slots={{
                                openPickerIcon: CalendarTodayIcon,
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: false,
                                    helperText: "",
                                    placeholder: "Select end date",
                                },
                            }}
                            disabled={!selectedDepartureStartDate}
                            minDate={selectedDepartureStartDate || undefined}
                            shouldDisableDate={(date: Dayjs) => {
                                if (!selectedDepartureStartDate) return true;
                                return date.isBefore(selectedDepartureStartDate, 'day');
                            }}
                        />
                    </Box>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Return Date"
                        value={selectedReturnDate}
                        onChange={(newDate: Dayjs | null) => {
                            setSelectedReturnDate(newDate)
                        }}
                        sx={{width: '100%'}}
                        slots={{
                            openPickerIcon: CalendarIcon,
                        }}
                        slotProps={{
                            field: {
                                clearable: true,
                                onClear: () => setSelectedReturnDate(null),
                            },
                        }}
                        disabled={!selectedDepartureStartDate || oneWay}
                        minDate={selectedDepartureStartDate || undefined}
                        shouldDisableDate={(date: Dayjs) => {
                            if (!selectedDepartureStartDate) return true;
                            return date.isBefore(selectedDepartureStartDate, 'day');
                        }}
                    />
                </LocalizationProvider>

                <Button
                    disabled={!selectedDepartureStartDate || !selectedDepartureEndDate || !departureInputValue || !selectedDestinationLocation || (!oneWay && !selectedReturnDate)}
                    variant="contained"
                    onClick={handleSearchButtonClick}
                    sx={{
                        // xs = extra small screens (mobile)
                        // md = medium screens (tablets/desktop)
                        height: {xs: '48px', md: '45px'}, // 48px height on mobile, 56px on larger screens
                        width: {xs: '100%', md: 'auto'}, // Full width on mobile, auto width on larger screens
                        minWidth: {md: '120px'} // Minimum width of 120px on larger screens
                    }}
                >
                    Search Flights
                </Button>
            </Box>
        </div>
    );
};

export default FlightSearch;