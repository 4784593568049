import React from 'react';
import { Link } from 'react-router-dom';

const About: React.FC = () => {
  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '2rem' }}>
      {/* Hero Section */}
      <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
        <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>
          About FlightGlimpse
        </h1>
        <p style={{ fontSize: '1.2rem', color: '#666' }}>
          Your Smart Flight Price Tracker
        </p>
      </div>

      {/* What We Do Section */}
      <div style={{ marginBottom: '3rem' }}>
        <h2 style={{ fontSize: '2rem', marginBottom: '1.5rem' }}>What We Do</h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '2rem' }}>
          <div style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <h3 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>🛫 Real-Time Price Tracking</h3>
            <p>Monitor flight prices in real-time across multiple routes and dates. Get instant notifications when prices drop.</p>
          </div>
          <div style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <h3 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>🔔 Smart Price Alerts</h3>
            <p>Set custom price alerts and receive notifications when your desired price is reached.</p>
          </div>
          <div style={{ padding: '2rem', backgroundColor: '#f8f9fa', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <h3 style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>📊 Historical Price Data</h3>
            <p>Access historical price trends to make informed decisions about when to book your flights.</p>
          </div>
        </div>
      </div>

      {/* Current Coverage Section */}
      <div style={{ marginBottom: '3rem' }}>
        <h2 style={{ fontSize: '2rem', marginBottom: '1.5rem' }}>Current Coverage</h2>
        <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
          Currently, we focus on tracking flights from Kiwi.com, one of the leading flight search engines. 
          We're continuously expanding our coverage to include more airlines and travel providers to give you 
          the most comprehensive flight price tracking experience.
        </p>
      </div>

      {/* What's Coming Soon Section */}
      <div style={{ marginBottom: '3rem' }}>
        <h2 style={{ fontSize: '2rem', marginBottom: '1.5rem' }}>What's Coming Soon</h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '2rem' }}>
          <div style={{ padding: '1.5rem', backgroundColor: '#f8f9fa', borderRadius: '10px' }}>
            <h3 style={{ fontSize: '1.3rem', marginBottom: '1rem' }}>✈️ More Airlines</h3>
            <p>Integration with major airlines and travel providers</p>
          </div>
          <div style={{ padding: '1.5rem', backgroundColor: '#f8f9fa', borderRadius: '10px' }}>
            <h3 style={{ fontSize: '1.3rem', marginBottom: '1rem' }}>📱 Mobile App</h3>
            <p>Track prices on the go with our mobile application</p>
          </div>
          <div style={{ padding: '1.5rem', backgroundColor: '#f8f9fa', borderRadius: '10px' }}>
            <h3 style={{ fontSize: '1.3rem', marginBottom: '1rem' }}>🎯 Price Predictions</h3>
            <p>AI-powered price predictions for better booking decisions</p>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div style={{ marginBottom: '3rem' }}>
        <h2 style={{ fontSize: '2rem', marginBottom: '1.5rem' }}>How It Works</h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '2rem' }}>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '2rem', marginBottom: '1rem' }}>1️⃣</div>
            <h3 style={{ fontSize: '1.3rem', marginBottom: '1rem' }}>Search Flights</h3>
            <p>Enter your desired route and travel dates</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '2rem', marginBottom: '1rem' }}>2️⃣</div>
            <h3 style={{ fontSize: '1.3rem', marginBottom: '1rem' }}>Set Alerts</h3>
            <p>Choose your preferred price range</p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '2rem', marginBottom: '1rem' }}>3️⃣</div>
            <h3 style={{ fontSize: '1.3rem', marginBottom: '1rem' }}>Get Notified</h3>
            <p>Receive alerts when prices match your criteria</p>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
        <h2 style={{ fontSize: '2rem', marginBottom: '1.5rem' }}>Ready to Start Tracking?</h2>
        <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>
          Join thousands of travelers who are already saving on their flights
        </p>
        <Link 
          to="/" 
          style={{
            display: 'inline-block',
            padding: '1rem 2rem',
            backgroundColor: '#007bff',
            color: 'white',
            textDecoration: 'none',
            borderRadius: '5px',
            fontSize: '1.1rem',
            transition: 'background-color 0.3s'
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
        >
          Start Tracking Now
        </Link>
      </div>

      {/* SEO Keywords Section */}
      <div style={{ 
        marginTop: '3rem', 
        padding: '2rem', 
        backgroundColor: '#f8f9fa', 
        borderRadius: '10px',
        fontSize: '0.9rem',
        color: '#666'
      }}>
        <p>
          FlightGlimpse is your go-to flight price tracker for finding the best deals on airline tickets. 
          We specialize in tracking flight prices, providing real-time price alerts, and helping you find 
          the cheapest flights. Whether you're looking for last-minute deals or planning ahead, our flight 
          search engine helps you compare prices across multiple airlines and travel dates. Get notified 
          when flight prices drop and save money on your next trip with our comprehensive flight tracking service.
        </p>
      </div>
    </div>
  );
};

export default About; 